import React from "react";
import "./style.scss";
import FeedbacksForm from "./feedbacksForm";
import { useParams } from "react-router-dom";
import { useFindForm } from "../../../../hooks/feedbacks/useFindForm";
import Loader from "../../../common/ui/loader/Loader";

const FeedbacksFormEdit: React.FC = () => {
  const { id } = useParams();
  const { data } = useFindForm(id);
  return (
    <div style={{ position: "relative", height: "calc(100vh - 64px)" }}>
      {data ? (
        <FeedbacksForm editMode formQuestions={data} formId={id} />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default FeedbacksFormEdit;
