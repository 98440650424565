import { useQuery } from "react-query";
import { statistics } from "../../api/statistics";
import moment from "moment";
import { useGlobalStore } from "../../store";
import { dateToIso } from "../../components/utils";

export const useStatisticsList = (startDate: string, endDate: string) => {
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  const start = dateToIso(moment(startDate).format("DD-MM-YYYY"));
  const end = dateToIso(moment(endDate).format("DD-MM-YYYY"));
  
  return useQuery(
    [
      "statistics",
      {
        startDate: start,
        endDate: end,
        selectedCompany,
      },
    ],
    () =>
      startDate &&
      endDate &&
      selectedCompany &&
      statistics.list(start, end, selectedCompany),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};
