import { useQuery } from "react-query";
import { feedbacks } from "../../api/feedbacks";
import { useGlobalStore } from "../../store";

export const useFeedbackCompanyAnswer = (id: number) => {
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  return useQuery(
    ["feedback-company-answer", id],
    () => feedbacks.listQuestionnairesCompany(id, selectedCompany),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};
