// HOC/AuthCheck.tsx
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/auth/useAuth";
import Loader from "../components/common/ui/loader/Loader";

const AuthCheck: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("accessToken");
  const { data, isLoading, isError, isFetching } = useAuth();

  useEffect(() => {
    if (!isLoading || isFetching) {
      if (isError && !token) {
        navigate("/auth/login");
      } else if (data && location.pathname === "/auth/login") {
        navigate("/");
      }
    }
  }, [isLoading, isError, data]);

  if (isLoading || isFetching) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return <>{children}</>;
};

export default AuthCheck;
