import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./style.scss";
import TextTitle from "../typography/TextTitle";

const CustomYTick: React.FC<any> = ({ x, y, payload, ...props }) => {
  return (
    <text x={x - 10} y={y - 3} {...props}>
      {payload.value}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label, ...props }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <TextTitle level='3'>{label}</TextTitle>
        {payload?.map((el: any) => {
          return (
            <div className='label'>
              <div
                style={{
                  background: el.color,
                  width: "8px",
                  height: "8px",
                  borderRadius: "2px",
                }}
              ></div>
              {`${el?.payload?.cardLabels[el.name] || el.name} : ${el.value}`}
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

interface ChartProps {
  data: any[];
  lines: { key: string; color: string }[];
}

const Chart: React.FC<ChartProps> = ({ data, lines }) => {
  return (
    <ResponsiveContainer width='100%' height={180}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis tickFormatter={value => `${value}`} tick={<CustomYTick />} />
        <Tooltip content={<CustomTooltip />} />
        {lines.map((line, index) => (
          <Line
            key={index}
            type='linear'
            dataKey={line.key}
            stroke={line.color}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
