import React from "react";
import "./style.scss";
import TextTitle from "../../../../../common/ui/typography/TextTitle";
import Icon from "../../../../../common/ui/Icon";
import TextCaption from "../../../../../common/ui/typography/TextCaption";
import InfoCard from "../../../../../common/ui/infoCard/InfoCard";
import Chart from "../../../../../common/ui/Chart/Chart";
import { TStatisticsCard } from "../../../../types";
import useStore from "../../../../store";

const StatisticsCard = ({ title, header, cards, chart }: TStatisticsCard) => {
  const isLoadingData = useStore(state => state.isLoadingData);
  return (
    <div className='staticticsCard'>
      <div className='staticticsCard__header'>
        <TextTitle level='2'>{title}</TextTitle>
        {/* <Icon icon='info' /> */}
      </div>
      <div className='staticticsCard__quantity'>
        <TextCaption level='3'>{header.title}</TextCaption>
        <TextTitle level='large'>{header.total}</TextTitle>
        <div
          className='d-flex gap-1'
          style={{
            color:
              header.newTotal > 0
                ? "#27AE60"
                : header.newTotal === 0
                  ? "#9b9daa"
                  : "#F64747",
          }}
        >
          {header.newTotal && header.newTotal > 0 ? (
            <Icon icon='arrow-trending-filled' />
          ) : header.newTotal === 0 ? (
            <Icon icon='arrow-right-filled' />
          ) : (
            <Icon icon='arrow-trending-filled-red' />
          )}
          <TextCaption level='2'>
            {header.newTotal > 0 ? "+" : ""}
            {header.newTotal} ({header.newPercentage}%)
          </TextCaption>
          <TextCaption level='3'>{header.statResultText}</TextCaption>
        </div>
      </div>
      <div className='d-flex w-100 flex-wrap' style={{ gap: "16px" }}>
        {cards?.map((card, i) => (
          <React.Fragment key={i}>
            <InfoCard
              title={card.title}
              color={card.color}
              amount={card.total}
              percent={`${card.newTotal ? (card.newTotal > 0 ? "+" : "") : ""}${card.newTotal || "0"} (${card.newPercentage}%)`}
              percentText={card.statResultText}
              background={card.background}
              total={card.newTotal}
              isLoading={isLoadingData}
            />
          </React.Fragment>
        ))}
      </div>
      <Chart data={chart.data} lines={chart.lines} />
    </div>
  );
};

export default StatisticsCard;
