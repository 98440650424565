import React from "react";
import "./style.scss";
import Icon from "../../common/ui/Icon";
import TextTitle from "../../common/ui/typography/TextTitle";
import TextCaption from "../../common/ui/typography/TextCaption";
import { useGlobalStore } from "../../../store";
import dock_left from "../../common/icons/dock_left.svg";

const Navbar: React.FC<{
  setSidebarOpen: (open: boolean) => void;
  sidebarOpen: boolean;
}> = ({ setSidebarOpen, sidebarOpen: sdOpen }) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const sidebarOpen = useGlobalStore(state => state.sidebarMobileOpen);
  const toggleSidebar = useGlobalStore(state => state.toggleMobileSidebar);
  return (
    <nav className='navbar'>
      <img
        src={dock_left}
        alt='dock left'
        className='navbar__dock-icon'
        onClick={() => setSidebarOpen(!sdOpen)}
        style={{ cursor: "pointer" }}
      />
      <div className='navbar__navigation' onClick={toggleSidebar}>
        <Icon icon={sidebarOpen ? "dismiss" : "navigation"} />
        <TextCaption level='1'>{sidebarOpen ? "Close" : "Menu"}</TextCaption>
      </div>
      <div className='navbar__profile'>
        <Icon icon='profile' />
        <div className='navbar__profile--title'>
          <TextTitle level='4'>TT ELD</TextTitle>
          <TextCaption level='1' className='navbar__profile--title-caption'>
            {user?.first_name || ""} {user?.second_name || ""}
          </TextCaption>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
