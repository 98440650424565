import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePickerInput.scss";
import Icon from "../ui/Icon";
import { DatePickerInputProps } from "./types";

const DatePickerInput: React.FC<DatePickerInputProps> = ({
  label,
  extraText,
  extraIcon,
  beforeIcon,
  afterIcon,
  value: propValue,
  placeholder,
  disabled,
  readonly,
  clearable,
  onFocus,
  onBlur,
  onChange,
  onClickExtra,
  onClickBefore,
  onClickAfter,
  clearIcon = "dismiss",
  state,
  helperText,
  title,
  textLevel,
  maxDate,
  minDate,
  dateFormat = "MMM d, yyyy",
  customClassName = "custom-datepicker",
}) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState<Date | null>(
    propValue ? new Date(propValue) : null,
  );
  const datePickerRef = useRef<DatePicker>(null);

  useEffect(() => {
    setValue(propValue ? new Date(propValue) : null);
  }, [propValue]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) onBlur(e);
  };

  const handleDateChange = (date: Date | null) => {
    setValue(date);
    if (datePickerRef.current) datePickerRef.current.setFocus();
    if (onChange) onChange(date);
  };

  const handleClear = () => {
    setValue(null);
    if (onChange) onChange(null);
    if (datePickerRef.current) datePickerRef.current.setFocus();
  };

  const handleClickExtra = () => {
    if (onClickExtra) onClickExtra();
  };

  const handleClickBefore = () => {
    if (onClickBefore) onClickBefore();
  };

  const handleClickAfter = () => {
    if (onClickAfter) onClickAfter();
  };

  const clearableClass = clearable && value ? "text-input__after" : "";

  return (
    <div
      className={`text-input ${focused ? (title ? "text-input--focus-title" : "text-input--focus") : ""} ${disabled ? "text-input--disabled" : ""} ${
        readonly ? "text-input--readonly" : ""
      } ${state === "error" ? "text-input--error" : ""}`}
      onMouseEnter={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
    >
      {(label || extraText || extraIcon) && (
        <div className='text-input__top' onClick={handleClickExtra}>
          {label && <div className='text-input__label'>{label}</div>}
          {extraText || extraIcon ? (
            <div className='text-input__extra'>
              <div className='text-input__extra-text'>{extraText}</div>
              {extraIcon && <Icon icon={extraIcon} size={16} />}
            </div>
          ) : null}
        </div>
      )}

      <div
        className={`text-input__field ${title ? "border-0 shadow-none h-auto p-0" : ""}`}
        onClick={() => datePickerRef.current?.setFocus()}
      >
        {beforeIcon && (
          <div className='text-input__before' onClick={handleClickBefore}>
            {beforeIcon && <Icon icon={beforeIcon} size={20} />}
          </div>
        )}
        <DatePicker
          ref={datePickerRef}
          selected={value}
          onChange={handleDateChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholderText={placeholder}
          disabled={disabled}
          readOnly={readonly}
          dateFormat={dateFormat}
          className={`text-input__input ${textLevel || "body-1"} ${customClassName}`}
          maxDate={maxDate}
          minDate={minDate}
          wrapperClassName='text-input__wrapper'
        />
        {clearable && value && (
          <div className={clearableClass} onClick={handleClear}>
            <Icon icon={clearIcon} size={20} className='text-input__clear' />
          </div>
        )}
        {afterIcon && (
          <div className='text-input__after' onClick={handleClickAfter}>
            <Icon icon={afterIcon} size={20} />
          </div>
        )}
      </div>

      {helperText ? (
        <div className='text-input__bottom'>
          {helperText && (
            <div
              className={`text-input__helper ${state === "error" ? "text-input__rule-message" : ""}`}
            >
              <div className='text-input__helper-text'>{helperText}</div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DatePickerInput;
