import { useQuery } from "react-query";
import { statistics } from "../../api/statistics";
import moment from "moment";
import { useGlobalStore } from "../../store";

export const useFinancialMetrics = () => {
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  return useQuery(
    ["financial-metrics", { selectedCompany }],
    () => selectedCompany && statistics.financialMetrics(selectedCompany),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};
