import React from "react";
import "./style.scss";
import StatisticsCard from "./components/StatisticsCard/StatisticsCard";
import useStore from "../../store";
import Loader from "../../../common/ui/loader/Loader";

const CompaniesStatistics: React.FC = () => {
  const driverData = useStore(state => state.driverData);
  const vehicleData = useStore(state => state.vehicleData);
  const companyData = useStore(state => state.companyData);
  const subscriptionData = useStore(state => state.subscriptionData);
  const userData = useStore(state => state.userData);
  const subscriptionCompanyData = useStore(
    state => state.subscriptionCompanyData,
  );
  return (
    <div
      style={{ position: "relative", minHeight: "400px", gap: "24px" }}
      className='d-flex flex-column'
    >
      {companyData && (
        <StatisticsCard
          title={companyData.title}
          header={companyData.header}
          cards={companyData.cards}
          chart={companyData.chart}
        />
      )}
      {subscriptionData && subscriptionCompanyData && (
        <div className='cardsFlex'>
          <StatisticsCard
            title={subscriptionData.title}
            header={subscriptionData.header}
            cards={subscriptionData.cards}
            chart={subscriptionData.chart}
          />
          <StatisticsCard
            title={subscriptionCompanyData.title}
            header={subscriptionCompanyData.header}
            cards={subscriptionCompanyData.cards}
            chart={subscriptionCompanyData.chart}
          />
        </div>
      )}
      {driverData && vehicleData ? (
        <div className='cardsFlex'>
          {vehicleData && (
            <StatisticsCard
              title={vehicleData.title}
              header={vehicleData.header}
              cards={vehicleData.cards}
              chart={vehicleData.chart}
            />
          )}
          {driverData && (
            <StatisticsCard
              title={driverData.title}
              header={driverData.header}
              cards={driverData.cards}
              chart={driverData.chart}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
      {userData && (
        <StatisticsCard
          title={userData.title}
          header={userData.header}
          cards={userData.cards}
          chart={userData.chart}
        />
      )}
    </div>
  );
};

export default CompaniesStatistics;
