import React, { useState, useRef, useEffect } from "react";
import "./textInput.scss";
import Icon from "../ui/Icon";
import { TextInputProps } from "./types";

const TextInput: React.FC<TextInputProps> = ({
  label,
  extraText,
  extraIcon,
  beforeIcon,
  afterIcon,
  value: propValue,
  placeholder,
  disabled,
  readonly,
  clearable,
  onFocus,
  onBlur,
  onChange,
  onClickExtra,
  onClickBefore,
  onClickAfter,
  clearIcon = "dismiss",
  state,
  helperText,
  type = "text",
  title,
  textLevel,
  multiline = false,
}) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(propValue || "");
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  useEffect(() => {
    setValue(propValue || "");
  }, [propValue]);

  useEffect(() => {
    if (multiline && inputRef.current) {
      const textarea = inputRef.current as HTMLTextAreaElement;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [value, multiline]);

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFocused(true);
    if (onFocus) onFocus(e as React.FocusEvent<HTMLInputElement>);
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFocused(false);
    if (onBlur) onBlur(e as React.FocusEvent<HTMLInputElement>);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleClear = () => {
    setValue("");
    if (onChange) onChange("");
    if (inputRef.current) inputRef.current.focus();
  };

  const handleClickExtra = () => {
    if (onClickExtra) onClickExtra();
  };

  const handleClickBefore = () => {
    if (onClickBefore) onClickBefore();
  };

  const handleClickAfter = () => {
    if (onClickAfter) onClickAfter();
  };

  const clearableClass = clearable && value ? "text-input__after" : "";

  return (
    <div
      className={`text-input ${focused ? (title ? "text-input--focus-title" : "text-input--focus") : ""} ${disabled ? "text-input--disabled" : ""} ${
        readonly ? "text-input--readonly" : ""
      } ${state === "error" ? "text-input--error" : ""}`}
      onMouseEnter={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
    >
      {(label || extraText || extraIcon) && (
        <div className='text-input__top' onClick={handleClickExtra}>
          {label && <div className='text-input__label'>{label}</div>}
          {extraText || extraIcon ? (
            <div className='text-input__extra'>
              <div className='text-input__extra-text'>{extraText}</div>
              {extraIcon && <Icon icon={extraIcon} size={16} />}
            </div>
          ) : null}
        </div>
      )}

      <div
        className={`text-input__field ${title ? "border-0 shadow-none h-auto p-0" : ""}`}
        onClick={() => inputRef.current?.focus()}
      >
        {beforeIcon && (
          <div className='text-input__before' onClick={handleClickBefore}>
            {beforeIcon && <Icon icon={beforeIcon} size={20} />}
          </div>
        )}
        {multiline ? (
          <textarea
            ref={inputRef as React.RefObject<HTMLTextAreaElement>}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readonly}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`text-input__input ${textLevel || "body-1"}`}
            style={{ resize: "none" }}
          />
        ) : (
          <input
            ref={inputRef as React.RefObject<HTMLInputElement>}
            value={value}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readonly}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`text-input__input ${textLevel || "body-1"}`}
          />
        )}
        {clearable && value && (
          <div className={clearableClass} onClick={handleClear}>
            <Icon icon={clearIcon} size={20} className='text-input__clear' />
          </div>
        )}
        {afterIcon && (
          <div className='text-input__after' onClick={handleClickAfter}>
            <Icon icon={afterIcon} size={20} />
          </div>
        )}
      </div>

      {helperText ? (
        <div className='text-input__bottom'>
          {helperText && (
            <div
              className={`text-input__helper ${state === "error" ? "text-input__rule-message" : ""}`}
            >
              <div className='text-input__helper-text'>{helperText}</div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default TextInput;
