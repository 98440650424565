import { useQuery } from "react-query";
import { feedbacks } from "../../api/feedbacks";
import { useGlobalStore } from "../../store";

export const useFindForm = (formId: number | string | undefined) => {
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  return useQuery(
    ["feedback-form", formId],
    () => feedbacks.find(formId, selectedCompany),
    {
      enabled: !!formId,
      staleTime: 0,
      cacheTime: 0,
    },
  );
};
