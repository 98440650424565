import Checkbox from "../../../../../common/form/Checkbox";
import { Radio } from "../../../../../common/form/Radio";
import TextArea from "../../../../../common/form/Textarea";
import TextInput from "../../../../../common/form/TextInput";
import Icon from "../../../../../common/ui/Icon";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import "./style.scss";
const FormCard = ({
  onChange,
  templates,
  onDragEnd,
  onCopyOption,
  onDeleteOption,
}: {
  onChange: (fieldUid: string, optionUid: string, value: string) => void;
  templates: any[];
  onDragEnd: (result: DropResult) => void;
  onCopyOption: (fieldUid: string, optionIndex: number) => void;
  onDeleteOption: (fieldUid: string, optionIndex: number) => void;
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {templates.map((field: any, i: number) => (
        <div
          key={"answers-" + field.uid}
          className='d-flex flex-column gap-3'
          style={{ marginTop: "12px" }}
        >
          {field.answerType === "checkbox" && (
            <Droppable droppableId={field.uid}>
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className='form d-flex flex-column'
                  style={{ gap: "12px" }}
                >
                  {field.answers?.map((option: any, index: number) => (
                    <Draggable
                      key={option.uid}
                      draggableId={option.uid}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "12px",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className='inputs'
                          >
                            <div {...provided.dragHandleProps}>
                              <Icon icon='union' size={"6px"} />
                            </div>
                            <Checkbox
                              modelValue={option.isMarked}
                              disabled={!option.isMarked}
                            />
                            <TextInput
                              onChange={(title: string) => {
                                onChange(field.uid, option.uid, title);
                              }}
                              clearIcon={"dismiss"}
                              placeholder='Write your answer here...'
                              title
                              textLevel='body-1'
                              value={option.title}
                            />
                            <div
                              className='d-flex gap-2 inputs__buttons'
                              style={{ marginLeft: "auto" }}
                            >
                              <div
                                onClick={() => onCopyOption(field.uid, index)}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon='copy' />
                              </div>
                              <div
                                onClick={() => onDeleteOption(field.uid, index)}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon='delete' />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div
                    style={{ marginLeft: "18px", gap: "12px" }}
                    key={"other"}
                  >
                    <Checkbox
                      modelValue={false}
                      label='Other'
                      disabled={true}
                    />
                    <div style={{ marginLeft: 30, width: "240px" }}>
                      <TextInput
                        type='text'
                        // value={option.note || ""}
                        placeholder='Type here...'
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          )}
          {field.answerType === "radio" && (
            <Droppable
              droppableId={field.uid}
              direction={field.isHorizontal ? "horizontal" : "vertical"}
            >
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`d-flex flex-wrap ${
                    field.isHorizontal ? "flex-row" : "flex-column"
                  }`}
                  style={{ gap: "12px" }}
                >
                  {field.answers?.map((option: any, index: number) => (
                    <Draggable
                      key={option.uid}
                      draggableId={option.uid}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "12px",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className='inputs'
                          >
                            <div {...provided.dragHandleProps}>
                              <Icon icon='union' size={"6px"} />
                            </div>
                            <Radio
                              key={index}
                              option={{
                                value: "",
                                label: "",
                              }}
                              value={option.isMarked ? option.title : false}
                              disabled={!option.isMarked}
                            />
                            <TextInput
                              onChange={(title: string) => {
                                onChange(field.uid, option.uid, title);
                              }}
                              clearIcon={"dismiss"}
                              placeholder='Write your answer here...'
                              title
                              textLevel='body-1'
                              value={option.title}
                            />
                            <div
                              className='d-flex gap-2 inputs__buttons'
                              style={{ marginLeft: "auto" }}
                            >
                              <div
                                onClick={() => onCopyOption(field.uid, index)}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon='copy' />
                              </div>
                              <div
                                onClick={() => onDeleteOption(field.uid, index)}
                                style={{ cursor: "pointer" }}
                              >
                                <Icon icon='delete' />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
          {field.answerType === "input" && (
            <TextInput
              type='text'
              value={field.note || ""}
              placeholder='Type here...'
              disabled
            />
          )}
          {field.answerType === "textarea" && (
            <TextArea
              type='text'
              modelValue={field.note || ""}
              placeholder='Type here...'
            />
          )}
        </div>
      ))}
    </DragDropContext>
  );
};

export default FormCard;
