import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useConfirm } from "../../hooks/useConfirm";
import "./style.scss";
import Icon from "../common/ui/Icon";
import Button from "../common/ui/buttons/Button";
import TextTitle from "../common/ui/typography/TextTitle";
import TextBody from "../common/ui/typography/TextBody";

const ConfirmModal: React.FC = () => {
  const { state } = useConfirm();
  return (
    <Modal
      isOpen={state.modal}
      style={{ borderRadius: "4px", width: 390 }}
      zIndex={9999}
    >
      <div className='text-center modal-container'>
        <div>
          <Icon
            size='40'
            color='error'
            icon={state.color === "blue" ? "archive-blue" : "archive-red"}
            className={"modal-icon"}
          />
        </div>
        <TextTitle className={"modal-title"} level={"1"}>
          {state.title}
        </TextTitle>
        <TextBody className={"modal-subtitle"} level={"1"}>
          {state.subtitle}
        </TextBody>
        <div className={"modal-buttons"}>
          <Button id='confirm-cancel'>{state.cancel}</Button>
          <Button
            id='confirm-yes'
            color={state.color || (state.type === "danger" ? "red" : "green")}
          >
            {state.ok}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
