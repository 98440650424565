import React, { useState, useMemo, useEffect, useCallback } from "react";
import "./style.scss";
import dock_left from "../../common/icons/dock_left.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Icon from "../../common/ui/Icon";
import tteld from "../../../assets/images/tteld.svg";
import evoeld from "../../../assets/images/evoeld.svg";
import ontime from "../../../assets/images/ontime.svg";
import zippyeld from "../../../assets/images/zippyeld.svg";
import { TIconsNames } from "../../common/iconSets";
import TextTitle from "../../common/ui/typography/TextTitle";
import TextCaption from "../../common/ui/typography/TextCaption";
import { useGlobalStore } from "../../../store";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import TextBody from "../../common/ui/typography/TextBody";
import { TDropdownElement } from "../../overview/types";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const sidebarMobileOpen = useGlobalStore(state => state.sidebarMobileOpen);
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  const setSelectedCompany = useGlobalStore(state => state.setSelectedCompany);
  const toggleMobileSidebar = useGlobalStore(
    state => state.toggleMobileSidebar,
  );
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const sidebarItems = useMemo<
    {
      link: string;
      title: string;
      icon: { active: TIconsNames; inActive: TIconsNames };
      show: boolean;
    }[]
  >(
    () => [
      {
        link: "/",
        title: "Overview",
        icon: { active: "view-desktop-filled", inActive: "view-desktop" },
        show: true,
      },
      {
        link: "/feedbacks",
        title: "Feedbacks",
        icon: { active: "comment-filled", inActive: "comment" },
        show: selectedCompany !== "all",
      },
      {
        link: "/heatmap",
        title: "Heatmap",
        icon: { active: "comment-filled", inActive: "comment" },
        show: selectedCompany !== "all",
      },
    ],
    [selectedCompany],
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1280);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile && sidebarMobileOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isMobile, sidebarMobileOpen]);

  const sidebarOpenCheck = isMobile ? sidebarMobileOpen : sidebarOpen;

  const closeSidebarOnMobile = () => {
    if (isMobile) {
      toggleMobileSidebar();
    }
  };

  const getLogo = (company: string) => {
    if (company.includes("tteld")) return tteld;
    if (company.includes("evoeld")) return evoeld;
    if (company.includes("ontime-logs")) return ontime;
    if (company.includes("zippyeld")) return zippyeld;
    return tteld;
  };

  const logo = useMemo(() => getLogo(selectedCompany), [selectedCompany]);

  const onLogout = () => {
    localStorage.clear();
    navigate("auth/login");
  };
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const dropdownElements: TDropdownElement[] = [
    { icon: "grid-filled", title: "All Companies", value: "all" },
    { icon: "tteld-logo", title: "TT ELD", value: "tteld" },
    { icon: "ontime-logo", title: "OnTime Logs", value: "ontime-logs" },
    { icon: "evo-logo", title: "EVO ELD", value: "evoeld" },
    { icon: "zippy-logo", title: "ZIPPY ELD", value: "zippyeld" },
  ];
  return (
    <>
      {sidebarOpenCheck && (
        <div className='sidebar-overlay' onClick={closeSidebarOnMobile} />
      )}
      <div className={`sidebar ${sidebarOpenCheck ? "open" : "closed"}`}>
        <div className='sidebarHeader'>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className='sidebar-dropdown'
          >
            <DropdownToggle className='sidebar-dropdown__toggle' caret>
              {sidebarOpenCheck &&
                (selectedCompany === "all" ? (
                  <div
                    style={{
                      color: "#212529",
                      display: "flex",
                      gap: "12px",
                      verticalAlign: "center",
                    }}
                  >
                    <Icon icon='grid-filled' />
                    <TextTitle level='3'>All Companies</TextTitle>
                  </div>
                ) : (
                  <img
                    src={logo}
                    alt='logo'
                    className='sidebar-dropdown__logo'
                  />
                ))}
              <div
                style={{
                  cursor: "pointer",
                  marginLeft: "auto",
                  transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.1s ease-in-out",
                }}
              >
                <Icon
                  icon='chevron-down-blue'
                  className='sidebar-dropdown__icon'
                />
              </div>
            </DropdownToggle>
            <DropdownMenu className='sidebar-dropdown__menu'>
              {dropdownElements.map(el => {
                return (
                  <DropdownItem
                    className='sidebar-dropdown__menu-item'
                    onClick={() => setSelectedCompany(el.value)}
                    active={el.value === selectedCompany}
                  >
                    <Icon
                      icon={el.icon}
                      className='sidebar-dropdown__menu-icon'
                      size={"20px"}
                    />
                    <TextBody level='1' className='sidebar-dropdown__menu-text'>
                      {el.title}
                    </TextBody>
                    {el.value === selectedCompany && (
                      <Icon
                        icon='checkmark-circle-filled-blue'
                        className='sidebar-dropdown__menu-item-icon'
                      />
                    )}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
        {sidebarOpenCheck && (
          <div className='sidebar__profile'>
            <Icon icon='profile' />
            <div className='sidebar__profile--title'>
              <TextTitle level='4'>TT ELD</TextTitle>
              <TextCaption
                level='1'
                className='sidebar__profile--title-caption'
              >
                {user.first_name || ""} {user.second_name || ""}
              </TextCaption>
            </div>
          </div>
        )}
        {sidebarItems.map(
          item =>
            item.show && (
              <div className='sidebarMenu' key={item.link}>
                <div className='sidebarSubMenu'>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "sidebarSubMenuHeadActive"
                        : "sidebarSubMenuHead"
                    }
                    to={item.link}
                    onClick={closeSidebarOnMobile}
                    style={
                      !sidebarOpenCheck
                        ? { justifyContent: "center", width: "44px" }
                        : undefined
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <Icon
                          icon={
                            isActive ? item.icon.active : item.icon.inActive
                          }
                        />
                        {sidebarOpenCheck && item.title}
                      </>
                    )}
                  </NavLink>
                </div>
              </div>
            ),
        )}
        <div
          className='sidebarMenu'
          style={{ marginTop: "auto", marginBottom: "20px" }}
          onClick={onLogout}
        >
          <div className='sidebarSubMenu'>
            <div className='sidebarSubMenuHead'>
              <Icon icon='logout' />
              {sidebarOpenCheck && "Logout"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
