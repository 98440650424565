import { useInfiniteQuery, useQueryClient } from "react-query";
import { feedbacks } from "../../api/feedbacks";
import { useGlobalStore } from "../../store";

export const useFeedbacksList = (formId: number) => {
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, ...rest } =
    useInfiniteQuery({
      queryKey: ["feedbacks-list", formId, selectedCompany],
      queryFn: ({ pageParam = 1 }) =>
        feedbacks.listQuestionnaires(formId, pageParam, selectedCompany),
      getNextPageParam: (lastPage, allPages) => {
        const totalFetched = allPages.reduce(
          (acc, page) => acc + page.data.length,
          0,
        );
        const totalAvailable = lastPage?.count ?? 0;
        return totalFetched < totalAvailable ? allPages.length + 1 : undefined;
      },
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60,
    });

  return {
    data: data?.pages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    ...rest,
  };
};

export const useFeedbacksListRefetch = () => {
  const queryClient = useQueryClient();
  const refetchPage = (page: number, formId: number) => {
    queryClient.invalidateQueries("feedbacks");
    queryClient.invalidateQueries(["feedbacks-list", formId], {
      refetchPage: (_, i) => i + 1 === page,
    });
  };

  return refetchPage;
};
