import React from "react";
import { useGlobalStore } from "../../store";
import { useQuery } from "react-query";
import { heatmap } from "../../api/heatmap";
import GoogleMapsHeatmap from "../../api/heatmap/GoogleMapsHeatmap";
import heatmapJson from "./heatmap.json";

export const HeatMap = () => {
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  // const { data } = useQuery(
  //   ["heatmap", selectedCompany],
  //   () => heatmap.snapshots(selectedCompany, "pickups"),
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //   },
  // );
  return (
    <div>{heatmapJson && <GoogleMapsHeatmap heatmapData={heatmapJson} />}</div>
  );
};
