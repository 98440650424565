import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";
import { DropResult } from "@hello-pangea/dnd";
import {
  TFeedbackAnswer,
  TFeedbackForm,
  TFeedbackQuestion,
} from "../../api/feedbacks/types";
import templates from "./components/feedbacksForm/templates.json";

interface FeedbackState {
  drawerOpen: boolean;
  isLoading: boolean;
  questions: TFeedbackForm;
  disabled: boolean;
  setDrawerOpen: (open: boolean) => void;
  setIsLoading: (loading: boolean) => void;
  setQuestions: (questions: TFeedbackForm) => void;
  setDisabled: (disabled: boolean) => void;
  chooseTemplate: (i: number) => void;
  onChangeTitle: (title: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeKey: (key: string) => void;
  onDragEnd: (result: DropResult) => void;
  toggleOptional: (index: number) => void;
  onDragEndCard: (result: DropResult) => void;
  onChangeCardField: (
    fieldUid: string,
    optionUid: string,
    title: string,
  ) => void;
  onCopyOption: (fieldUid: string, optionIndex: number) => void;
  createOption: (fieldUid: string) => void;
  onDeleteOption: (fieldUid: string, optionIndex: number) => void;
  onDuplicateQuestion: (questionIndex: number) => void;
  onDeleteQuestion: (questionIndex: number) => void;
  onChangeQuestionTitle: (title: string, index: number) => void;
  reset: () => void;
}

interface FeedbacksPageState {
  activeTabIndex: number;
  isDrawerOpen: boolean;
  isCompanyDrawerOpen: boolean;
  companyIdName: {
    id: number;
    name: string;
    page: number;
    user?: { fullname: string; email: string };
  } | null;
  formId: number | undefined;
  answerInput: { questionId: number; title: string } | null;
  setDrawerOpen: (isOpen: boolean) => void;
  setCompanyDrawerOpen: (isOpen: boolean) => void;
  setCompanyIdName: (
    companyIdName: {
      id: number;
      name: string;
      page: number;
      user?: { fullname: string; email: string };
    } | null,
  ) => void;
  setFormId: (id: number | undefined) => void;
  setAnswerInput: (input: { questionId: number; title: string } | null) => void;
  handleCloseFeedbacksDrawer: () => void;
  handleCloseDrawer: () => void;
  handleCloseStatisticsDrawer: () => void;
  handleTabChange: (index: number) => void;
  reset: () => void;
}

export const useFeedbackCreateStore = create<FeedbackState>((set, get) => ({
  drawerOpen: false,
  isLoading: false,
  questions: {
    title: "",
    description: "",
    key: "",
    questions: [],
  },
  disabled: true,
  setDrawerOpen: open => set({ drawerOpen: open }),
  setIsLoading: loading => set({ isLoading: loading }),
  setQuestions: questions => set({ questions }),
  setDisabled: disabled => set({ disabled }),
  chooseTemplate: i =>
    set(state => ({
      questions: {
        ...state.questions,
        questions: [
          ...state.questions.questions,
          {
            ...(templates[i] as TFeedbackQuestion),
            uid: uuidv4(),
            answers: templates[i].answers?.map(el => ({
              ...el,
              uid: uuidv4(),
            })),
          },
        ],
      },
      drawerOpen: false,
    })),
  onChangeTitle: title =>
    set(state => ({
      questions: { ...state.questions, title },
    })),
  onChangeDescription: description =>
    set(state => ({
      questions: { ...state.questions, description },
    })),
  onChangeKey: key => {
    const sanitizedKey = key.replace(/ /g, "-");
    set(state => ({
      questions: { ...state.questions, key: sanitizedKey },
    }));
  },
  onDragEnd: (result: DropResult) => {
    if (!result.destination) return;
    const { questions } = get();
    const { source, destination } = result;
    const updatedQuestions = [...questions.questions];
    const [movedQuestion] = updatedQuestions.splice(source.index, 1);
    updatedQuestions.splice(destination.index, 0, movedQuestion);
    set(state => ({
      questions: { ...state.questions, questions: updatedQuestions },
    }));
  },
  toggleOptional: index =>
    set(state => ({
      questions: {
        ...state.questions,
        questions: state.questions.questions.map((question, idx) =>
          idx === index
            ? { ...question, isOptional: !question.isOptional }
            : question,
        ),
      },
    })),
  onDragEndCard: result => {
    const { source, destination } = result;
    const { questions } = get();
    if (!destination || source.index === destination.index) return;
    const updatedQuestions = questions.questions.map(question => {
      if (question.uid === source.droppableId) {
        const updatedAnswers = [...(question.answers as TFeedbackAnswer[])];
        const [removed] = updatedAnswers.splice(source.index, 1);
        updatedAnswers.splice(destination.index, 0, removed);
        return { ...question, answers: updatedAnswers };
      }
      return question;
    });
    set({ questions: { ...questions, questions: updatedQuestions } });
  },
  onChangeCardField: (fieldUid, optionUid, title) =>
    set(state => {
      const updatedQuestions = state.questions.questions.map(question => {
        if (question.uid !== fieldUid) return question;
        const updatedAnswers = question.answers?.map(answer => {
          if (answer.uid !== optionUid) return answer;
          return { ...answer, title };
        });
        return { ...question, answers: updatedAnswers };
      });
      return { questions: { ...state.questions, questions: updatedQuestions } };
    }),
  onCopyOption: (fieldUid, optionIndex) =>
    set(state => ({
      questions: {
        ...state.questions,
        questions: state.questions.questions.map(el =>
          el.uid === fieldUid && el.answers
            ? {
                ...el,
                answers: [
                  ...el.answers.slice(0, optionIndex + 1),
                  { ...el.answers[optionIndex], uid: uuidv4(), id: null },
                  ...el.answers.slice(optionIndex + 1),
                ],
              }
            : el,
        ),
      },
    })),
  createOption: fieldUid =>
    set(state => ({
      questions: {
        ...state.questions,
        questions: state.questions.questions.map(el =>
          el.uid === fieldUid && el.answers
            ? {
                ...el,
                answers: [...el.answers, { title: "", uid: uuidv4() }],
              }
            : el,
        ),
      },
    })),
  onDeleteOption: (fieldUid, optionIndex) =>
    set(state => ({
      questions: {
        ...state.questions,
        questions: state.questions.questions.map(el =>
          el.uid === fieldUid && el.answers
            ? {
                ...el,
                answers: el.answers.filter((_, index) => index !== optionIndex),
              }
            : el,
        ),
      },
    })),
  onDuplicateQuestion: questionIndex =>
    set(state => ({
      questions: {
        ...state.questions,
        questions: [
          ...state.questions.questions.slice(0, questionIndex + 1),
          {
            ...state.questions.questions[questionIndex],
            uid: uuidv4(),
            id: null,
            answers: state.questions.questions[questionIndex].answers?.map(
              answer => ({
                ...answer,
                uid: uuidv4(),
                id: null,
              }),
            ),
          },
          ...state.questions.questions.slice(questionIndex + 1),
        ],
      },
    })),
  onDeleteQuestion: questionIndex =>
    set(state => ({
      questions: {
        ...state.questions,
        questions: state.questions.questions.filter(
          (_, index) => index !== questionIndex,
        ),
      },
    })),
  onChangeQuestionTitle: (title, index) =>
    set(state => {
      const updatedQuestions = state.questions.questions.map((question, idx) =>
        idx === index ? { ...question, title } : question,
      );
      return { questions: { ...state.questions, questions: updatedQuestions } };
    }),
  reset: () => {
    set(states => ({
      ...states,
      drawerOpen: false,
      isLoading: false,
      questions: {
        title: "",
        description: "",
        key: "",
        questions: [],
      },
      disabled: true,
    }));
  },
}));

export const useFeedbacksPageStore = create<FeedbacksPageState>(set => ({
  activeTabIndex: 0,
  isDrawerOpen: false,
  isCompanyDrawerOpen: false,
  companyIdName: null,
  formId: undefined,
  answerInput: null,
  setDrawerOpen: isOpen => set({ isDrawerOpen: isOpen }),
  setCompanyDrawerOpen: isOpen => set({ isCompanyDrawerOpen: isOpen }),
  setCompanyIdName: companyIdName => set({ companyIdName }),
  setFormId: id => set({ formId: id }),
  setAnswerInput: input => set({ answerInput: input }),
  handleCloseFeedbacksDrawer: () =>
    set({ isCompanyDrawerOpen: false, companyIdName: null }),
  handleCloseDrawer: () => set({ isDrawerOpen: false }),
  handleCloseStatisticsDrawer: () => set({ answerInput: null }),
  handleTabChange: index => set({ activeTabIndex: index }),
  reset: () => {
    set(states => ({
      ...states,
      activeTabIndex: 0,
      isDrawerOpen: false,
      isCompanyDrawerOpen: false,
      companyIdName: null,
      formId: undefined,
      answerInput: null,
    }));
  },
}));
