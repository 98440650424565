import React, { useState } from "react";
import { Tab } from "../../../common/ui/tabs/Tab";
import { Tabs } from "../../../common/ui/tabs/Tabs";
import "./style.scss";
import TextBody from "../../../common/ui/typography/TextBody";
import Icon from "../../../common/ui/Icon";
import { useFeedbacksList } from "../../../../hooks/feedbacks/useFeedbacksList";
import moment from "moment";
import Loader from "../../../common/ui/loader/Loader";
import CompanyFeedbacksStatistics from "./CompanyFeedbacksStatistics";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";
import TextCaption from "../../../common/ui/typography/TextCaption";
import TextCallout from "../../../common/ui/typography/TextCallout";

const CompanyFeedbacksList = ({
  formId,
  setCompanyDrawerOpen,
  setCompanyIdName,
  setAnswerInput,
}: {
  formId: number;
  setCompanyDrawerOpen: (value: boolean) => void;
  setCompanyIdName: ({
    id,
    name,
    page,
    user,
  }: {
    id: number;
    name: string;
    page: number;
    user: { fullname: string; email: string };
  }) => void;
  setAnswerInput: ({
    questionId,
    title,
  }: {
    questionId: number;
    title: string;
  }) => void;
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useFeedbacksList(formId);
  const lastElementRef = useInfiniteScroll({
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    isFetchingNextPage,
    isLoading,
  });

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);
  };

  const feedbackDate = (dateString: string) => {
    const now = moment();
    const date = moment(dateString);
    const diffMinutes = now.diff(date, "minutes");
    const diffHours = now.diff(date, "hours");
    if (diffMinutes < 60) {
      return `${diffMinutes} min ago, ${date.format("HH:mm A")}`;
    } else if (diffHours < 24) {
      return `${diffHours} hours ago, ${date.format("HH:mm A")}`;
    } else {
      return date.format("DD MMM, HH:mm A");
    }
  };

  const feedbacksDate = (dateString: string): string => {
    const date = moment(dateString);
    if (date.isSame(moment(), "day")) {
      return "Today";
    } else if (date.isSame(moment().subtract(1, "day"), "day")) {
      return "Yesterday";
    } else if (date.isSame(moment(), "year")) {
      return date.format("MMM D");
    } else {
      return date.format("MMM D, YYYY");
    }
  };

  const openDrawer = (
    id: number,
    name: string,
    j: number,
    fullname: string,
    email: string,
  ) => {
    setCompanyDrawerOpen(true);
    setCompanyIdName({
      id,
      name,
      page: j + 1,
      user: {
        fullname,
        email,
      },
    });
  };

  return (
    <div>
      <Tabs modelValue={activeTabIndex} onTabChange={handleTabChange}>
        <Tab icon='comment' activeIcon='comment-filled' text='Feedbacks' />
        <Tab
          icon='data-histogram'
          activeIcon='data-histogram-filled'
          text='Statistics'
        />
      </Tabs>
      {activeTabIndex === 0 ? (
        isLoading ? (
          <Loader />
        ) : (
          <div className='feedbacks'>
            <div className='feedbacks__list'>
              {data ? (
                data?.map((items: any, j: number) => {
                  return items?.data?.map((el: any, i: number) => {
                    const isLastElement = i === items?.data.length - 1;
                    return (
                      <React.Fragment key={i}>
                        {(i === 0 ||
                          !moment(el.createdAt).isSame(
                            moment(items?.data[i - 1].createdAt),
                            "day",
                          )) &&
                          (j === 0 ||
                            (data[j - 1].data &&
                              !moment(el.createdAt).isSame(
                                moment(
                                  data[j - 1].data[data[j - 1].data.length - 1]
                                    .createdAt,
                                ),
                                "day",
                              ))) && (
                            <div className='feedbacks__date'>
                              <TextBody
                                level='1'
                                className='feedbacks__date-text'
                              >
                                {feedbacksDate(el.createdAt)}
                              </TextBody>
                            </div>
                          )}
                        <div
                          className='feedbacks__list-item'
                          onClick={() =>
                            openDrawer(
                              el.id,
                              el.company.name,
                              j,
                              (el?.user?.first_name
                                ? el?.user?.first_name
                                : "") +
                                " " +
                                (el?.user?.second_name
                                  ? el?.user?.second_name
                                  : ""),
                              el?.user?.email || "No User Email",
                            )
                          }
                          style={
                            el.isRead
                              ? { backgroundColor: "var(--Light-mode-Grey-01)" }
                              : {}
                          }
                          ref={isLastElement ? lastElementRef : undefined}
                        >
                          <div className='feedbacks__list-item-unread'>
                            <div
                              style={
                                el.isRead
                                  ? {
                                      backgroundColor:
                                        "var(--Light-mode-Grey-01)",
                                    }
                                  : {}
                              }
                            ></div>
                          </div>
                          <div
                            className='feedbacks__list-item-company'
                            style={
                              el.isRead ||
                              (items?.data[i + 1] &&
                                !moment(el.createdAt).isSame(
                                  moment(items?.data[i + 1].createdAt),
                                  "day",
                                ))
                                ? { border: "none" }
                                : {}
                            }
                          >
                            <div className='feedbacks__list-item-company-titles'>
                              <TextBody
                                className='feedbacks__list-item-company-name'
                                level='2'
                              >
                                {el.company.name}
                              </TextBody>
                              {el?.user && (
                                <div className='feedbacks__list-item-company-user'>
                                  <Icon icon='person' />
                                  <TextCallout
                                    level='1'
                                    className='font-weight-bold'
                                  >
                                    {el.user.first_name +
                                      " " +
                                      el.user.second_name}
                                  </TextCallout>
                                  <TextCaption level='3'>
                                    ({el.user.email})
                                  </TextCaption>
                                </div>
                              )}
                              <div className='feedbacks__list-item-company-titles-date'>
                                {feedbackDate(el.createdAt)}
                              </div>
                            </div>
                            <Icon
                              icon='chevron-right'
                              className='feedbacks__list-item-company-icon'
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  });
                })
              ) : (
                <Loader />
              )}
              {isFetchingNextPage && (
                <div style={{ padding: "12px 24px" }}>
                  <TextBody level={"2"}>Loading...</TextBody>
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <CompanyFeedbacksStatistics
          formId={formId}
          setAnswerInput={setAnswerInput}
        />
      )}
    </div>
  );
};

export default CompanyFeedbacksList;
