import React, { useCallback, useEffect } from "react";
import "./style.scss";
import Button from "../../../common/ui/buttons/Button";
import TextBody from "../../../common/ui/typography/TextBody";
import TextTitle from "../../../common/ui/typography/TextTitle";
import DrawerView from "../../../common/ui/navigationDrawer/DrawerView";
import NavigationDrawer from "../../../common/ui/navigationDrawer/NavigationDrawer";
import FeedbackTemplates from "./components/templates";
import TextInput from "../../../common/form/TextInput";
import Icon from "../../../common/ui/Icon";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import Checkbox from "../../../common/form/Checkbox";
import FormCard from "./components/form";
import { TFeedbackForm } from "../../../../api/feedbacks/types";
import { feedbacks } from "../../../../api/feedbacks";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../../hooks/useAlert";
import { useFeedbackCreateStore } from "../../store";
import { useGlobalStore } from "../../../../store";

const FeedbacksForm: React.FC<{
  editMode?: boolean;
  formQuestions?: TFeedbackForm;
  formId?: undefined | string | number;
}> = ({ editMode, formQuestions, formId }) => {
  const {
    drawerOpen,
    isLoading,
    questions,
    disabled,
    setDrawerOpen,
    setIsLoading,
    setDisabled,
    chooseTemplate,
    onChangeTitle,
    onChangeDescription,
    onChangeKey,
    onDragEnd,
    toggleOptional,
    onDragEndCard,
    onChangeCardField,
    onCopyOption,
    createOption,
    onDeleteOption,
    onDuplicateQuestion,
    onDeleteQuestion,
    onChangeQuestionTitle,
    setQuestions,
    reset,
  } = useFeedbackCreateStore();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const sidebarOpen = useGlobalStore(state => state.sidebarOpen);
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  useEffect(() => {
    const hasEmptyTitle = questions.questions.some(
      question =>
        !question.title.trim() ||
        (question.answers &&
          question.answers.some(answer => !answer.title.trim())),
    );
    if (
      !questions.questions.length ||
      !questions.title.trim() ||
      // !questions.description?.trim() ||
      !questions.key.trim() ||
      hasEmptyTitle
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [questions]);

  useEffect(() => {
    if (formQuestions) {
      setQuestions(formQuestions);
    }
    return () => {
      reset();
    };
  }, []);

  const editFeedback = useCallback(async () => {
    try {
      setIsLoading(true);
      await feedbacks.update(questions, formId, selectedCompany);
      showAlert("success", "Form successfully edited!");
      navigate("/feedbacks");
    } catch (err: any) {
      showAlert(
        "error",
        err?.response?.data?.error.message || "Something went wrong!",
      );
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [formId, questions, selectedCompany]);

  const createFeedback = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await feedbacks.create(questions, selectedCompany);
      if (data?.error) {
        showAlert("error", data?.error.message || "Something went wrong!");
        return;
      }
      showAlert("success", "Form successfully created!");
      navigate("/feedbacks");
    } catch (err: any) {
      showAlert(
        "error",
        err?.response?.data?.error.message || "Something went wrong!",
      );
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [questions, selectedCompany]);

  return (
    <div className='feedbacksForm'>
      <NavigationDrawer
        modelValue={drawerOpen}
        onUpdateModelValue={() => setDrawerOpen(false)}
        width='1248px'
        zIndex={"2201"}
        zIndexOverlay={"2101"}
      >
        <DrawerView
          closeDrawer={() => setDrawerOpen(false)}
          header={({ closeDrawer }) => (
            <div className={"d-flex align-items-center w-100 gap-3"}>
              <Button
                size={"xs"}
                onClick={closeDrawer}
                beforeIcon={"arrow_left"}
                beforeClass='mr-0'
              />
              <TextTitle level={"1"} className='d-flex align-center gap-2'>
                Feedbacks form templates
              </TextTitle>
            </div>
          )}
        >
          <FeedbackTemplates chooseTemplate={chooseTemplate} />
        </DrawerView>
      </NavigationDrawer>
      <div className='feedbacksForm__header'>
        <TextTitle className=''>
          {editMode ? "Edit" : "New"} feedback form
        </TextTitle>
      </div>
      <div className='feedbacksForm__templates'>
        <div className='feedbacksForm__templates__header'>
          <div className='feedbacksForm__templates__header--title'>
            <TextInput
              onChange={onChangeTitle}
              clearIcon={"dismiss"}
              placeholder='Title'
              title
              textLevel='title-large'
              value={questions.title}
            />
          </div>
          <div className='feedbacksForm__templates__header--description'>
            <TextInput
              onChange={onChangeDescription}
              clearIcon={"dismiss"}
              placeholder='Write description here'
              title
              textLevel='title-3'
              value={questions.description}
            />
          </div>
          <div className='feedbacksForm__templates__header--description'>
            <TextInput
              onChange={onChangeKey}
              clearIcon={"dismiss"}
              placeholder='Write key here'
              title
              textLevel='title-3'
              value={questions.key}
            />
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd} key={"questions"}>
          <Droppable droppableId='questions'>
            {provided => (
              <div
                className='feedbacksForm__templates__list'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {questions.questions.map((items, index: number) => (
                  <Draggable
                    key={items.uid}
                    draggableId={items.uid}
                    index={index}
                  >
                    {provided => (
                      <div
                        className='feedbacksForm__templates__template'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div className='feedbacksForm__templates__template--header'>
                          <div {...provided.dragHandleProps}>
                            <Icon icon='union' size={"6px"} />
                          </div>
                          <TextInput
                            onChange={(title: string) =>
                              onChangeQuestionTitle(title, index)
                            }
                            clearIcon={"dismiss"}
                            placeholder='Write your question here...'
                            title
                            textLevel='title-1'
                            value={items.title}
                            multiline
                          />
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginLeft: "auto",
                            }}
                          >
                            <Button
                              size={"xs"}
                              onClick={() => onDuplicateQuestion(index)}
                              beforeIcon={"copy"}
                              beforeClass='mr-0'
                            />
                            <Button
                              size={"xs"}
                              onClick={() => onDeleteQuestion(index)}
                              beforeIcon={"delete"}
                              beforeClass='mr-0'
                            />
                          </div>
                        </div>
                        <div className='feedbacksForm__templates__template--body'>
                          <div style={{ marginLeft: "18px" }}>
                            <Checkbox
                              label='* Required'
                              labelSlot={({ label }) => (
                                <TextBody level='1' className='required'>
                                  {label}
                                </TextBody>
                              )}
                              onClick={() => toggleOptional(index)}
                              modelValue={!items.isOptional}
                            />
                          </div>
                          <FormCard
                            onChange={onChangeCardField}
                            templates={[items]}
                            onDragEnd={onDragEndCard}
                            onCopyOption={onCopyOption}
                            onDeleteOption={onDeleteOption}
                          />
                          {(items.answerType === "checkbox" ||
                            items.answerType === "radio") && (
                            <div
                              style={{
                                marginTop: "16px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "12px",
                              }}
                            >
                              <TextBody level='1'>Add</TextBody>
                              <div>
                                <Button
                                  beforeIcon={"text"}
                                  size='xs'
                                  onClick={() => createOption(items.uid)}
                                >
                                  Text answer
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className='feedbacksForm__addSection'>
        <div className='button-container'>
          <Button
            size='square'
            beforeIcon='add_filled'
            beforeClass='mr-0'
            onClick={() => setDrawerOpen(true)}
          />
        </div>
        <TextBody level='1'>Add a section</TextBody>
      </div>
      <div
        className='feedbacksForm__footer'
        style={!sidebarOpen ? { width: "calc(100% - 68px)" } : {}}
      >
        <Button size='xs' onClick={() => navigate("/feedbacks")} esc>
          Cancel
        </Button>
        <Button
          main={!disabled && !isLoading}
          block={disabled || isLoading}
          size='xs'
          onClick={editMode ? editFeedback : createFeedback}
          disabled={disabled || isLoading}
          enter
        >
          {editMode ? "Accept" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default FeedbacksForm;
