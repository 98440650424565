import create from "zustand";

export type TSelectedCompany =
  | "all"
  | "tteld"
  | "evoeld"
  | "ontime-logs"
  | "zippyeld";
interface SidebarState {
  sidebarOpen: boolean;
  sidebarMobileOpen: boolean;
  toggleSidebar: () => void;
  toggleMobileSidebar: () => void;
  setSidebarOpen: (open: boolean) => void;
  selectedCompany: TSelectedCompany;
  setSelectedCompany: (company: TSelectedCompany) => void;
}

export const useGlobalStore = create<SidebarState>(set => ({
  sidebarOpen: JSON.parse(localStorage.getItem("sidebar") || "true"),
  sidebarMobileOpen: false,
  toggleSidebar: () =>
    set(state => {
      const newSidebarState = !state.sidebarOpen;
      localStorage.setItem("sidebar", JSON.stringify(newSidebarState));
      return { sidebarOpen: newSidebarState };
    }),
  toggleMobileSidebar: () =>
    set(state => {
      return { sidebarMobileOpen: !state.sidebarMobileOpen };
    }),
  setSidebarOpen: (open: boolean) => {
    localStorage.setItem("sidebar", JSON.stringify(open));
    set({ sidebarOpen: open });
  },
  selectedCompany:
    (localStorage.getItem("selectedCompany") as TSelectedCompany) || "tteld",
  setSelectedCompany: (company: TSelectedCompany) => {
    set(state => {
      localStorage.setItem("selectedCompany", company);
      return { selectedCompany: company };
    });
  },
}));
