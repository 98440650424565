import { useQuery, useMutation, useQueryClient } from "react-query";
import { feedbacks } from "../../api/feedbacks";
import { TFeedback } from "../../api/feedbacks/types";
import { useAlert } from "../useAlert";
import { TSelectedCompany } from "../../store";
import { useFeedbacksPageStore } from "../../components/feedbacks/store";

export const useFeedbacks = (
  isActive: boolean,
  selectedCompany: TSelectedCompany,
) => {
  const queryClient = useQueryClient();
  const { showAlert } = useAlert();
  const queryKey = ["feedbacks", isActive, selectedCompany];
  const handleCloseFeedbacksDrawer = useFeedbacksPageStore(
    state => state.handleCloseFeedbacksDrawer,
  );
  const { data, isLoading, isFetching } = useQuery(
    queryKey,
    () => feedbacks.list(isActive, selectedCompany),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const deactivateMutation = useMutation(
    async (formId: number) => {
      const { message } = await feedbacks.deactivate(formId, selectedCompany);
      return message;
    },
    {
      onSuccess: message => {
        showAlert("success", message);
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err: any) => {
        showAlert(
          "error",
          err?.response?.data?.error.message || "Something went wrong!",
        );
      },
    },
  );

  const reactivateMutation = useMutation(
    async (formId: number) => {
      const { message } = await feedbacks.reactivate(formId, selectedCompany);
      return message;
    },
    {
      onSuccess: message => {
        showAlert("success", message);
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err: any) => {
        showAlert(
          "error",
          err?.response?.data?.error.message || "Something went wrong!",
        );
      },
    },
  );

  const removeMutation = useMutation(
    async (formId: number) => {
      const { message } = await feedbacks.remove(formId, selectedCompany);
      return message;
    },
    {
      onSuccess: message => {
        showAlert("success", message);
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err: any) => {
        showAlert(
          "error",
          err?.response?.data?.error.message || "Something went wrong!",
        );
      },
    },
  );

  const removeAnswerMutation = useMutation(
    async (questionnaireId: number) => {
      const { message } = await feedbacks.deleteAnswer(
        questionnaireId,
        selectedCompany,
      );
      return message;
    },
    {
      onSuccess: message => {
        showAlert("success", message);
        handleCloseFeedbacksDrawer();
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err: any) => {
        showAlert(
          "error",
          err?.response?.data?.error.message || "Something went wrong!",
        );
      },
    },
  );

  return {
    data: data
      ? data.data?.map((el: TFeedback) => {
          const name = el.title;
          const added_date = el.createdAt;
          const added_by = `${el?.addedBy?.first_name || ""} ${el?.addedBy?.second_name || ""}`;
          return {
            name,
            added_date,
            added_by,
            statistics: el.statistics,
            id: el.id,
            key: el.key,
          };
        })
      : [],
    isLoading,
    isFetching,
    deactivate: deactivateMutation.mutate,
    reactivate: reactivateMutation.mutate,
    remove: removeMutation.mutate,
    removeAnswerMutation: removeAnswerMutation.mutate,
  };
};
