import React, { useEffect, useRef } from "react";
import Table from "../common/Table/Table";
import { TableColumn } from "../common/Table/TableTypes";
import TextTitle from "../common/ui/typography/TextTitle";
import "./styles.scss";
import Icon from "../common/ui/Icon";
import Button from "../common/ui/buttons/Button";
import { Tabs } from "../common/ui/tabs/Tabs";
import { Tab } from "../common/ui/tabs/Tab";
import DrawerView from "../common/ui/navigationDrawer/DrawerView";
import NavigationDrawer from "../common/ui/navigationDrawer/NavigationDrawer";
import CancelingSubscriptionList from "./components/companyFeedbacks/CompanyFeedbacksList";
import Menu from "../common/ui/menu/Menu";
import TextBody from "../common/ui/typography/TextBody";
import { useFeedbacks } from "./../../hooks/feedbacks/useFeedbacks";
import { TFeedbacksList } from "./types";
import { useConfirm } from "../../hooks/useConfirm";
import CompanyAnswer from "./components/companyAnswer";
import { useQueryClient } from "react-query";
import AnswersList from "./components/answers";
import moment from "moment";
import { useFeedbacksListRefetch } from "../../hooks/feedbacks/useFeedbacksList";
import { useNavigate } from "react-router-dom";
import { useFeedbacksPageStore } from "./store";
import { useGlobalStore } from "../../store";
import TextCallout from "../common/ui/typography/TextCallout";
import TextCaption from "../common/ui/typography/TextCaption";

const FeedbacksPage = () => {
  const isInitialMount = useRef(true);
  const {
    activeTabIndex,
    isDrawerOpen,
    isCompanyDrawerOpen,
    companyIdName,
    formId,
    answerInput,
    setDrawerOpen,
    setCompanyDrawerOpen,
    setCompanyIdName,
    setFormId,
    setAnswerInput,
    handleCloseFeedbacksDrawer,
    handleCloseDrawer,
    handleCloseStatisticsDrawer,
    handleTabChange,
    reset,
  } = useFeedbacksPageStore();
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const selectedCompany = useGlobalStore(state => state.selectedCompany);

  const {
    data,
    isLoading,
    deactivate,
    reactivate,
    remove,
    removeAnswerMutation,
    isFetching,
  }: {
    data: TFeedbacksList[];
    isLoading: boolean;
    isFetching: boolean;
    deactivate: (formId: number) => void;
    reactivate: (formId: number) => void;
    remove: (formId: number) => void;
    removeAnswerMutation: (questionnaireId: number) => void;
  } = useFeedbacks(!activeTabIndex, selectedCompany);

  const feedbacksListRefetch = useFeedbacksListRefetch();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return () => {
        reset();
      };
    }
  }, []);
  const onClickRow = (item: TFeedbacksList) => {
    setFormId(item.id);
    setDrawerOpen(true);
  };
  const columns: TableColumn<TFeedbacksList>[] = [
    { header: "#", accessor: "num", clickRow: onClickRow },
    {
      header: "Name",
      accessor: "name",
      clickRow: onClickRow,
      minWidth: "200px",
    },
    {
      header: "Key",
      accessor: "key",
      clickRow: onClickRow,
      minWidth: "200px",
    },
    {
      header: "Added date",
      accessor: "added_date",
      clickRow: onClickRow,
      minWidth: "100px",
    },
    {
      header: "Added by",
      accessor: "added_by",
      clickRow: onClickRow,
      minWidth: "150px",
    },
    {
      header: "Feedback amount",
      accessor: "statistics",
      clickRow: onClickRow,
      minWidth: "130px",
    },
    {
      header: "Actions",
      accessor: "actions",
      align: "end",
      cellClass: "text-end",
    },
  ];
  const handleArchive = async (id: number) => {
    const confirmed = await confirm({
      title: "Are you sure you want to archive this form?",
      subtitle:
        "When you archive a form, it will be deactivated and moved to Archived tab. You can unarchive or delete it from there.",
      ok: "Archive",
      type: "danger",
    });
    if (confirmed) {
      deactivate(id);
    }
  };
  const handleUnarchive = async (id: number) => {
    const confirmed = await confirm({
      title: "Are you sure you want to unarchive this form?",
      subtitle:
        "When you unarchive a form, it will be activated and moved to Active tab.",
      ok: "Unarchive",
      type: "success",
      color: "blue",
    });
    if (confirmed) {
      reactivate(id);
    }
  };
  const handleDelete = async (id: number) => {
    const confirmed = await confirm({
      title: "Are you sure you want to delete this form?",
      subtitle:
        "When you delete a form, it will be removed from the system permanently and cannot be restored.",
      ok: "Delete",
      type: "danger",
    });
    if (confirmed) {
      remove(id);
    }
  };
  useEffect(() => {
    return () => {
      if (isCompanyDrawerOpen && companyIdName?.page && formId) {
        feedbacksListRefetch(companyIdName.page, formId);
      }
    };
  }, [isCompanyDrawerOpen]);

  const handleDeleteAnswer = async (id: number) => {
    const confirmed = await confirm({
      title: "Are you sure you want to delete this answer?",
      subtitle:
        "When you delete a answer, it will be removed from the system permanently and cannot be restored.",
      ok: "Delete",
      type: "danger",
    });
    if (confirmed) {
      removeAnswerMutation(id);
    }
  };

  return (
    <div className={"feedback"}>
      <NavigationDrawer
        modelValue={!!answerInput}
        onUpdateModelValue={() => setAnswerInput(null)}
        width='1248px'
        zIndex={"2201"}
        zIndexOverlay={"2101"}
      >
        <DrawerView
          closeDrawer={handleCloseStatisticsDrawer}
          header={({ closeDrawer }) => (
            <div className={"d-flex align-items-center w-100 gap-3"}>
              <Button
                size={"xs"}
                onClick={closeDrawer}
                beforeIcon={"arrow_left"}
                beforeClass='mr-0'
              />
              <TextTitle level={"1"} className='d-flex align-center gap-2'>
                {answerInput?.title}
              </TextTitle>
            </div>
          )}
        >
          {answerInput?.questionId && (
            <AnswersList
              questionId={answerInput.questionId}
              setCompanyIdName={setCompanyIdName}
              setCompanyDrawerOpen={setCompanyDrawerOpen}
            />
          )}
        </DrawerView>
      </NavigationDrawer>
      <NavigationDrawer
        modelValue={isCompanyDrawerOpen}
        onUpdateModelValue={setCompanyDrawerOpen}
        width='1248px'
        zIndex={"2202"}
        zIndexOverlay={"2102"}
      >
        <DrawerView
          closeDrawer={handleCloseFeedbacksDrawer}
          header={({ closeDrawer }) => (
            <>
              <div className={"d-flex align-items-start w-100 gap-3"}>
                <Button
                  size={"xs"}
                  onClick={closeDrawer}
                  beforeIcon={"arrow_left"}
                  beforeClass='mr-0'
                />
                <div>
                  <TextTitle level={"1"} className='d-flex align-center gap-2'>
                    {companyIdName?.name}’s feedback
                  </TextTitle>
                  {companyIdName?.user && (
                    <div className='d-flex flex-wrap gap-1 mt-1'>
                      <Icon icon='person' />
                      <TextCallout level='1' className='font-weight-bold'>
                        <span style={{ fontWeight: 600 }}>
                          {companyIdName?.user?.fullname}
                        </span>
                      </TextCallout>
                      <TextCaption level='3'>
                        ({companyIdName?.user?.email})
                      </TextCaption>
                    </div>
                  )}
                </div>
                {companyIdName && (
                  <div className='delete_answer'>
                    <Button
                      onClick={() => {
                        companyIdName && handleDeleteAnswer(companyIdName?.id);
                      }}
                      beforeIcon={"delete"}
                      beforeClass='feedback__button'
                    >
                      <span className='feedback__button--text'>Delete</span>
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        >
          {companyIdName?.id && <CompanyAnswer companyId={companyIdName.id} />}
        </DrawerView>
      </NavigationDrawer>
      <NavigationDrawer
        modelValue={isDrawerOpen}
        onUpdateModelValue={setDrawerOpen}
        width='1248px'
      >
        <DrawerView
          closeDrawer={handleCloseDrawer}
          header={({ closeDrawer }) => (
            <div className={"d-flex align-items-center w-100"}>
              <TextTitle level={"1"} className='d-flex align-center gap-2'>
                Feedbacks
              </TextTitle>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  size={"xs"}
                  onClick={closeDrawer}
                  beforeIcon={"dismiss"}
                  beforeClass='mr-0'
                />
              </div>
            </div>
          )}
        >
          {formId && isDrawerOpen && (
            <CancelingSubscriptionList
              formId={formId}
              setCompanyDrawerOpen={setCompanyDrawerOpen}
              setCompanyIdName={setCompanyIdName}
              setAnswerInput={setAnswerInput}
            />
          )}
        </DrawerView>
      </NavigationDrawer>
      <div className={"feedback__header d-flex align-items-center"}>
        <TextTitle level={"1"} className='d-flex align-center gap-2'>
          Feedback
          {/* <Icon icon={"info"} /> */}
        </TextTitle>
        <div style={{ marginLeft: "auto" }} className={"d-flex gap-2"}>
          <Button
            main
            onClick={() => {
              console.log("hi");
            }}
            beforeIcon={"plus"}
            to={"/feedbacks/create"}
            beforeClass='feedback__button'
          >
            <span className='feedback__button--text'>Add new form</span>
          </Button>
          <Button
            beforeIcon={"arrow-clockwise"}
            onClick={() => {
              queryClient.invalidateQueries(["feedbacks"]);
            }}
            beforeClass='feedback__button'
          >
            <span className='feedback__button--text'>Refresh</span>
          </Button>
        </div>
      </div>
      <Tabs modelValue={activeTabIndex} onTabChange={handleTabChange}>
        <Tab
          icon='text-bullet-list-square'
          activeIcon='text-bullet-list-square-filled'
          text='Active forms'
        />
        <Tab icon='archive' activeIcon='archive-filled' text='Archived' />
      </Tabs>
      <div className='feedbacks-table-wrapper'>
        {data && (
          <Table
            columns={columns}
            data={data}
            renderRow={(item, column, rowIndex) => {
              if (column.accessor === "num") {
                return <>{rowIndex + 1}</>;
              }
              if (column.accessor === "actions") {
                return (
                  <>
                    {activeTabIndex ? (
                      <div
                        className='d-flex justify-content-end'
                        style={{ gap: "12px" }}
                      >
                        <Button
                          beforeIcon={"delete"}
                          color={"red"}
                          beforeClass={"unarchive-icon"}
                          onClick={() => handleDelete(item.id)}
                          size='xs'
                        >
                          Delete
                        </Button>
                        <Button
                          beforeIcon={"archive"}
                          color={"blue"}
                          beforeClass={"unarchive-icon"}
                          onClick={() => handleUnarchive(item.id)}
                          size='xs'
                        >
                          Unarchive
                        </Button>
                      </div>
                    ) : (
                      <Menu
                        activator={({ onClick }) => (
                          <Button
                            beforeIcon={"more_filled"}
                            beforeClass='mr-0'
                            size={"xs"}
                            onClick={onClick}
                          />
                        )}
                        isOpen={false}
                        onClose={() => {}}
                      >
                        <div
                          className='menu__item'
                          onClick={() => {
                            navigate(`/feedbacks/edit/${item.id}`);
                          }}
                        >
                          <Icon icon={"edit"} />
                          <TextBody
                            level={"1"}
                            className='d-flex align-center gap-2'
                          >
                            Edit
                          </TextBody>
                        </div>
                        <div
                          className='menu__item'
                          onClick={() => {
                            handleArchive(item.id);
                          }}
                        >
                          <Icon icon={"archive-red"} />
                          <TextBody
                            level={"1"}
                            className='d-flex align-center gap-2'
                          >
                            Deactivate
                          </TextBody>
                        </div>
                      </Menu>
                    )}
                  </>
                );
              }
              if (column.accessor === "statistics") {
                return (
                  <div>
                    {item.statistics.read}
                    {item.statistics.unread ? (
                      <div className={"unread"}>+ {item.statistics.unread}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
              if (column.accessor === "added_date") {
                return <>{moment(item.added_date).format("MMM D, YYYY")}</>;
              }
              return item[column.accessor];
            }}
            isLoading={isLoading || isFetching}
          />
        )}
      </div>
    </div>
  );
};

export default FeedbacksPage;
